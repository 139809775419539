// see https://github.com/nextui-org/nextui/blob/main/packages/core/theme/src/components/spinner.ts
import { tv } from "@nextui-org/react";
import type { VariantProps } from "tailwind-variants";

/**
 * Spinner wrapper **Tailwind Variants** component
 *
 *
 * @example
 * <div className={base())}>
 *    <i className={loader()}/>
 *    <span className={label()}/>
 * </div>
 */
const spinner = tv({
  slots: {
    base: "relative inline-flex flex-col items-center justify-center",
    wrapper: "relative flex",
    loader: "absolute w-full h-full animate-spin",
    label: "text-foreground",
  },
  variants: {
    size: {
      xs: {
        wrapper: "w-4 h-4",
        loader: "!stroke-xs",
      },
      sm: {
        wrapper: "w-5 h-5",
        loader: "!stroke-sm",
      },
      md: {
        wrapper: "w-8 h-8",
        loader: "!stroke-md",
      },
      lg: {
        wrapper: "w-10 h-10",
        loader: "!stroke-lg",
      },
    },
    color: {
      current: {
        loader: "stroke-current",
      },
      default: {
        loader: "stroke-default",
      },
      primary: { loader: "stroke-primary" },
      secondary: { loader: "stroke-secondary" },
      success: {
        loader: "stroke-success",
      },
      warning: { loader: "stroke-warning" },
      danger: { loader: "stroke-danger" },
    },
  },
  defaultVariants: {
    size: "md",
    color: "default",
  },
});

export type SpinnerVariantProps = VariantProps<typeof spinner>;
export type SpinnerSlots = keyof ReturnType<typeof spinner>;

export { spinner };
