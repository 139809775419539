import accounts from "./endpoints/accounts";
import analytics from "./endpoints/analytics";
import auctioneer from "./endpoints/auctioneer";
import auth from "./endpoints/auth";
import bedrock from "./endpoints/bedrock";
import careseekers from "./endpoints/careseekers";
import * as chroma from "./endpoints/chroma";
import crypto from "./endpoints/crypto";
import requests from "./endpoints/requests";
import transport from "./transport";

export default {
  auth,
  analytics,
  chroma,
  crypto,
  auctioneer,
  careseekers,
  requests,
  accounts,
  bedrock,
  setTokenAccessor: transport.setTokenAccessor,
  setAuthAccessor: transport.setAuthAccessor,
  setTokenExpiration: transport.setTokenExpiration,
};
