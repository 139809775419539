import { PaletteOptions } from "@mui/material";
import { red } from "@mui/material/colors";
import {
  ACCENT_COLOR,
  ACCENT_DARK_COLOR,
  ACCENT_LIGHT_COLOR,
  CUSTOM_BLACK,
  ERROR_COLOR,
  ERROR_DARK_COLOR,
  ERROR_LIGHT_COLOR,
  KEYBOARD_FOCUS_OUTLINE,
  SUCCESS_COLOR,
  SUCCESS_DARK_COLOR,
  SUCCESS_LIGHT_COLOR,
  WHITE,
  grey,
} from "../colors";

type OutlineColors = {
  main: string;
};

declare module "@mui/material/styles" {
  interface Palette {
    outline: OutlineColors;
  }

  interface PaletteOptions {
    outline?: OutlineColors;
  }
}

export const paletteShared: PaletteOptions = {
  secondary: {
    dark: ACCENT_DARK_COLOR,
    main: ACCENT_COLOR,
    light: ACCENT_LIGHT_COLOR,
    contrastText: WHITE,
  },
  common: {
    white: WHITE,
    black: CUSTOM_BLACK,
  },
  error: {
    ...red,
    main: ERROR_COLOR,
    light: ERROR_LIGHT_COLOR,
    dark: ERROR_DARK_COLOR,
  },
  success: {
    main: SUCCESS_COLOR,
    light: SUCCESS_LIGHT_COLOR,
    dark: SUCCESS_DARK_COLOR,
  },
  outline: {
    main: KEYBOARD_FOCUS_OUTLINE,
  },
  mode: "light",
  grey,
} as const;
