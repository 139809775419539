import {
  ThemeProvider as MuiThemeProviderV5,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { NextUIProvider } from "@nextui-org/react";
import { NEXTUI_LOCALE, THEME_CLASSES } from "core/consts";
import { isStoryshots } from "core/model/utils/featureFlags";
import { AppType } from "core/types";
import { getTheme } from "ds_legacy/materials/theme";
import { ThemeProvider as Emotion10ThemeProvider } from "emotion-theming";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { useLocale } from "translations";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function useAddThemeClass({ app }: { app?: AppType }) {
  useEffect(() => {
    if (!app) return;

    const targetElement = document.body;
    if (!targetElement) {
      console.error("No appropriate target element found");
      return;
    }

    const existingThemes = Object.values(THEME_CLASSES);

    existingThemes.forEach((theme) => {
      if (theme && targetElement.classList.contains(theme)) {
        targetElement.classList.remove(theme);
      }
    });

    const themeClass = THEME_CLASSES[app];
    if (themeClass) {
      targetElement.classList.add(`overscroll-none`, themeClass);
    }

    return () => {
      if (themeClass) {
        targetElement.classList.remove(`overscroll-none`, themeClass);
      }
    };
  }, [app]);
}

export function NextUIThemeProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  const locale = useLocale();
  useAddThemeClass({ app });
  const navigate = useNavigate();

  return (
    <NextUIProvider
      disableAnimation={isStoryshots}
      locale={NEXTUI_LOCALE[locale ?? "de"]}
      navigate={navigate}
    >
      {children}
    </NextUIProvider>
  );
}

export default function RecareThemeProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  return (
    <Emotion10ThemeProvider theme={getTheme(app)}>
      <StyledThemeProvider theme={getTheme(app)}>
        <MuiThemeProviderV5 theme={getTheme(app)}>
          <ThemeProvider theme={getTheme(app)}>{children}</ThemeProvider>
        </MuiThemeProviderV5>
      </StyledThemeProvider>
    </Emotion10ThemeProvider>
  );
}
