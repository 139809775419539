// ----------- FLAG --------------
const activateNetDebugger = false;
// -------------------------------

let netDebugger: HTMLDivElement | undefined;

if (activateNetDebugger) {
  netDebugger = document.createElement("div");
  netDebugger.style.height = "300px";
  netDebugger.style.width = "300px";
  netDebugger.style.position = "absolute";
  netDebugger.style.bottom = "80px";
  netDebugger.style.left = "80px";
  netDebugger.style.background = "black";
  netDebugger.style.wordBreak = "break-all";
  netDebugger.style.overflowY = "auto";
  netDebugger.style.opacity = "70%";
  netDebugger.style.zIndex = "1000";
  netDebugger.style.pointerEvents = "none";

  document.addEventListener(
    "focus",
    function () {
      if (!netDebugger) return;
      const x = document.activeElement?.getBoundingClientRect().x ?? 0;
      const half = document.body.clientWidth / 2;
      if (x > half) {
        // focus on the right, debugger on the left
        netDebugger.style.left = "80px";
        netDebugger.style.right = "auto";
      } else {
        // focus on the left, debugger on the right
        netDebugger.style.left = "auto";
        netDebugger.style.right = "80px";
      }
    },
    true,
  );

  netDebugger.addEventListener("networkrequest", (e) => {
    const details: Array<string> = JSON.parse(
      (e as CustomEvent<string>).detail,
    );
    details.forEach((detail, index) => {
      if (!netDebugger) return;
      const isLast = index === details.length - 1;
      const log = document.createElement("p");
      log.style.color = "white";
      log.style.fontFamily = "monospace";
      log.style.margin = "0";
      if (isLast) log.style.marginBottom = "8px";
      log.textContent = detail;
      if (detail.includes("CONSOLE LOG")) log.style.color = "yellow";
      else if (detail.includes("CONSOLE ERROR")) log.style.color = "red";
      netDebugger.appendChild(log);
      netDebugger.scrollTop = netDebugger.scrollHeight;
    });
  });

  document.body.appendChild(netDebugger);

  const oldLog = console.log;
  console.log = function (...data: any) {
    logConsole({ message: data.map(String).join(" ") });
    oldLog.apply(console, data);
  };
  const oldError = console.error;
  console.error = function (...data: any) {
    logConsole({ message: data.map(String).join(" "), error: true });
    oldError.apply(console, data);
  };
}

export function logNetEvent({
  err,
  method,
  reqBody,
  resBody,
  url,
}: {
  err?: Error;
  method: string;
  reqBody?: FormData | string;
  resBody?: Response | string;
  url: string;
}) {
  if (!activateNetDebugger) return;
  const detail = [];
  detail.push(`${method} ${url}`);
  if (reqBody) {
    if (String(reqBody).length > 30) {
      detail.push(`\xa0\xa0> ${String(reqBody).substring(0, 30)}...`);
    } else {
      detail.push(`\xa0\xa0> ${String(reqBody)}`);
    }
  }
  if (err) {
    detail.push(`\xa0\xa0< ERROR ${err}`);
  } else if (resBody) {
    detail.push(
      `\xa0\xa0< ${
        typeof resBody === "string"
          ? resBody.length > 30
            ? `${String(resBody).substring(0, 30)}...`
            : resBody
          : resBody.status || "200"
      }`,
    );
  }
  const event = new CustomEvent<string>("networkrequest", {
    detail: JSON.stringify(detail),
  });
  netDebugger?.dispatchEvent(event);
}

function logConsole({ error, message }: { error?: boolean; message: string }) {
  if (!activateNetDebugger) return;
  const detail = [`CONSOLE ${error ? "ERROR" : "LOG"}: ${message}`];
  const event = new CustomEvent<string>("networkrequest", {
    detail: JSON.stringify(detail),
  });
  netDebugger?.dispatchEvent(event);
}
