import { visuallyHidden } from "@mui/utils";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { useA11yMessage } from "./A11yStatusContext";

const a11yContainer = document.getElementById("a11y-status-container");

function A11yStatus() {
  const {
    update: { message, role },
  } = useA11yMessage();
  const key = useRef(0);

  if (!a11yContainer) return null;

  return createPortal(
    <div
      data-testid="a11y-status"
      style={visuallyHidden}
      role={role}
      aria-atomic // Firefox support -> see https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/bitv-20-web-9-4-1-3-statusmeldungen-programmatisch-verfuegbar
      aria-relevant="additions"
    >
      {/* key forces re-rendering and re-reading the message even if the message was identical to the last update */}
      <div key={key.current++}>{message}</div>
    </div>,
    a11yContainer,
  );
}

export default A11yStatus;
