import { startCase } from "lodash";
import z from "zod";

const CAPITALIZED_REGEX = /^(?:[A-Z][a-z]*\s?)+$/;

export const stringOrNull = z.nullable(z.string());
export const numberOrNull = z.nullable(z.number());

export const capitalizedStringSchema = z.string().regex(CAPITALIZED_REGEX, {
  message: "all words in string must be capitalized",
});

export const dateFormatRFC3339 = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/);

export function generateTrackName<T extends "Button" | "Link" = "Button">({
  id,
  type = "Button" as T,
}: {
  id: string;
  type?: T;
}) {
  const trackName = `${startCase(startCase(id).toLowerCase())} ${type} Clicked`;

  return trackName as T extends "Button"
    ? `${string} Button Clicked`
    : `${string} Link Clicked`;
}
