// Extended from https://github.com/nextui-org/nextui/blob/142f394cb5d25c97c919782d03f74c3b310eab0c/packages/core/theme/src/utils/variants.ts
import { colorVariants as NextUIColorVariants } from "@nextui-org/react";

const faded = {
  ...NextUIColorVariants.faded,
  primary: "bg-white",
};

export const colorVariants = {
  ...NextUIColorVariants,
  faded,
};
