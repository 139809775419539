import { useSearchInsights as useAlgoliaSearchInsights } from "dsl/atoms/AlgoliaSelect/search-insights";
import { usePageTracking } from "dsl/hooks";
import { ReactNode } from "react";

export default function PageTracker({ children }: { children: ReactNode }) {
  const { Track } = usePageTracking();
  useAlgoliaSearchInsights();

  return <Track>{children}</Track>;
}
