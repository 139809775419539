import { PaletteOptions } from "@mui/material";
import {
  ACP_PRIMARY_COLOR,
  ACP_PRIMARY_DARK_COLOR,
  ACP_PRIMARY_LIGHT_COLOR,
  WHITE,
} from "../colors";
import { paletteShared } from "./shared";

export const paletteACP: PaletteOptions = {
  ...paletteShared,
  primary: {
    dark: ACP_PRIMARY_DARK_COLOR,
    main: ACP_PRIMARY_COLOR,
    light: ACP_PRIMARY_LIGHT_COLOR,
    contrastText: WHITE,
  },
} as const;
