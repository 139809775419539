import { Truthy } from "../../../types";

declare global {
  interface Array<T> {
    clone(): Array<T>;
    truthy(): Array<Truthy<T>>;
  }
  interface ReadonlyArray<T> {
    clone(): Array<T>;
    truthy(): Array<Truthy<T>>;
  }
}

Object.defineProperty(Array.prototype, "truthy", {
  value: function () {
    return this.filter(Boolean);
  },
});

Object.defineProperty(Array.prototype, "clone", {
  value: function () {
    return this.slice(0);
  },
});

export default {};
