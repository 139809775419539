import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

function useSafeState<S>(
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>];

function useSafeState<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>,
];

function useSafeState<S>(initialState?: S | (() => S)) {
  const safe = useRef(true);
  const [state, setState] = useState(initialState);

  useEffect(
    () => () => {
      safe.current = false;
    },
    [],
  );

  const safelySetState = useCallback(
    (nextState: SetStateAction<S | undefined>): void => {
      if (safe.current) setState(nextState);
    },
    [],
  );

  return [state, safelySetState];
}

export default useSafeState;
