import { useEffect } from "react";

export const useExplicitErrorHandler = () => {
  useEffect(() => {
    const listenerCallback = (event: any) => {
      if (event?.message === "ResizeObserver loop limit exceeded") {
        event?.stopImmediatePropagation();
      }
    };
    window.addEventListener("error", listenerCallback);
    return () => window.removeEventListener("error", listenerCallback);
  }, []);
};
