import appState from "./appState";
import networkState from "./networkState";
import persistReducer from "./persistReducer";
import settings from "./settings";

export default (persist?: boolean) => ({
  networkState,
  appState,
  auth: persistReducer(persist),
  settings,
});
