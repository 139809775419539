import { ToastAria, useToast } from "@react-aria/toast";
import { DOMProps } from "@react-types/shared";
import { Button, ToastNotificationProps } from "ds/ui";
import {
  CheckCircleIcon,
  CircleAlertIcon,
  CircleSlashIcon,
  InfoIcon,
  XIcon,
} from "lucide-react";
import { ElementType, useRef } from "react";
import { tv, type VariantProps } from "tailwind-variants";

type ToastVariant = VariantProps<typeof toastVariants>;
type ToastColor = Exclude<ToastVariant["color"], undefined>;

export type ToastContentType = DOMProps & {
  action?: {
    label: string;
    onClick?: () => void;
  };
  color: ToastColor;
  message: string;
};

const toastVariants = tv({
  slots: {
    base: "flex items-center px-4 py-2 tablet:p-4 rounded-lg shadow-md text-body-sm gap-2 tablet:min-w-[400px] max-w-[600px] justify-between ",
    content: "flex items-center gap-4",
    icon: "h-5 w-5 flex-shrink-0 hidden tablet:block",
    actions: "flex items-center gap-2",
    closeIcon: "h-5 w-5 flex-shrink-0",
  },
  variants: {
    color: {
      success: "bg-success text-success-foreground",
      danger: "bg-danger text-danger-foreground",
      primary: "bg-primary text-primary-foreground",
      warning: "bg-warning text-warning-foreground",
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

export const ICONS: Record<ToastColor, ElementType> = {
  primary: InfoIcon,
  danger: CircleSlashIcon,
  success: CheckCircleIcon,
  warning: CircleAlertIcon,
};

export const ToastContent = ({
  closeButtonProps,
  state,
  titleProps,
  toast: {
    content: { action, color, message },
    key,
  },
}: ToastNotificationProps<ToastContentType> &
  Pick<ToastAria, "closeButtonProps" | "titleProps">) => {
  const Icon = ICONS[color];
  const { actions, base, closeIcon, content, icon } = toastVariants({ color });

  return (
    <div data-testid="notification-message" className={base()}>
      <div className={content()}>
        <Icon className={icon()} />
        <p {...titleProps}>{message}</p>
      </div>
      <div className={actions()}>
        {action && (
          <Button
            color={color}
            id="notification-message-action"
            onPress={action.onClick}
            size="sm"
            variant="solid"
          >
            {action.label}
          </Button>
        )}
        <Button
          {...closeButtonProps}
          color={color}
          id="notification-close"
          isIconOnly
          onPress={() => state.close(key)}
          size="xs"
          variant="solid"
        >
          <XIcon className={closeIcon()} />
        </Button>
      </div>
    </div>
  );
};

export const Toast = ({
  state,
  ...props
}: ToastNotificationProps<ToastContentType>) => {
  const ref = useRef(null);
  const { closeButtonProps, contentProps, titleProps, toastProps } = useToast(
    props,
    state,
    ref,
  );

  return (
    <div ref={ref} {...toastProps}>
      <div {...contentProps}>
        <ToastContent
          state={state}
          closeButtonProps={closeButtonProps}
          titleProps={titleProps}
          toast={props.toast}
        />
      </div>
    </div>
  );
};

Toast.displayName = "RecareUI.ToastVariant";
