import { persistReducer as basePersistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";

export const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["data", "networkState", "appState"], // navigation will not be persisted
};

const authPersistConfig = {
  key: "auth",
  storage,
};

export default function persistReducer(persist?: boolean) {
  if (persist) return basePersistReducer(authPersistConfig, auth);
  return auth;
}
