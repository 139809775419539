import { PaletteOptions } from "@mui/material";
import {
  BLACK,
  CUSTOM_WHITE,
  GREY_50,
  GREY_600,
  KEYBOARD_FOCUS_OUTLINE,
  WHITE,
  grey,
} from "../colors";

type ProviderSearchNeutralColors = {
  base_dark: string;
  base_light: string;
  custom_blue: string;
  custom_light_blue: string;
  custom_white: string;
  dark_grey: string;
  light_grey: string;
  medium_grey: string;
};

declare module "@mui/material/styles" {
  interface Palette {
    neutrals: ProviderSearchNeutralColors;
  }

  interface PaletteOptions {
    neutrals?: ProviderSearchNeutralColors;
  }
}

// NEUTRALS
export const BCP_LIGHT_GREY = GREY_50;
export const BCP_MEDIUM_GREY = "#D8D8D8";
export const BCP_DARK_GREY = GREY_600;
export const BCP_CUSTOM_WHITE = CUSTOM_WHITE;
export const BCP_BASE_LIGHT = "#E6E6D5";
export const BCP_BASE_DARK = "#102832";
export const BCP_CUSTOM_BLUE = "#2E4E8E";
export const BCP_CUSTOM_LIGHT_BLUE = "#EBF1FB";

// COMMON
export const BCP_WHITE = WHITE;
export const BCP_BLACK = BLACK;

// PRIMARY
export const BCP_PRIMARY_DARK = "#AB1A9A";
export const BCP_PRIMARY_MAIN = "#C920B5";
export const BCP_PRIMARY_LIGHT = "#BEA3C6";

// SECONDARY
export const BCP_SECONDARY_DARK = "#CFC0BD";
export const BCP_SECONDARY_LIGHT = "#F1DAC4";

// SUCCESS
export const BCP_SUCCESS_DARK = "#006144";
export const BCP_SUCCESS_MAIN = "#00845C";
export const BCP_SUCCESS_LIGHT = "#E0F9E7";

// ERROR
export const BCP_ERROR_DARK = "#A1160A";
export const BCP_ERROR_MAIN = "#D92E22";
export const BCP_ERROR_LIGHT = "#FAECEC";

// WARNING
export const BCP_WARNING_DARK = "#8F3415";
export const BCP_WARNING_MAIN = "#FFA656";
export const BCP_WARNING_LIGHT = "#FFF5E7";

export const paletteBCP: PaletteOptions = {
  primary: {
    dark: BCP_PRIMARY_DARK,
    main: BCP_PRIMARY_MAIN,
    light: BCP_PRIMARY_LIGHT,
  },
  secondary: {
    dark: BCP_SECONDARY_DARK,
    main: BCP_SECONDARY_DARK,
    light: BCP_SECONDARY_LIGHT,
  },
  common: {
    black: BCP_BLACK,
    white: BCP_WHITE,
  },
  error: {
    main: BCP_ERROR_MAIN,
    light: BCP_ERROR_LIGHT,
    dark: BCP_ERROR_DARK,
  },
  success: {
    main: BCP_SUCCESS_MAIN,
    light: BCP_SUCCESS_LIGHT,
    dark: BCP_SUCCESS_DARK,
  },
  warning: {
    main: BCP_WARNING_MAIN,
    light: BCP_WARNING_LIGHT,
    dark: BCP_WARNING_DARK,
  },
  neutrals: {
    base_dark: BCP_BASE_DARK,
    base_light: BCP_BASE_LIGHT,
    custom_white: BCP_CUSTOM_WHITE,
    custom_blue: BCP_CUSTOM_BLUE,
    custom_light_blue: BCP_CUSTOM_LIGHT_BLUE,
    dark_grey: BCP_DARK_GREY,
    light_grey: BCP_LIGHT_GREY,
    medium_grey: BCP_MEDIUM_GREY,
  },
  outline: {
    main: KEYBOARD_FOCUS_OUTLINE,
  },
  mode: "light",
  grey,
} as const;
