import { NEVER } from "rxjs";
import { FormElementContainerProps } from "./FormElementContainer";
import { getValue } from "./StateValueHelpers";

export const NeverObservable = NEVER;

function getNewPathPart(
  elementName: string | undefined,
  statePath: string | null,
  v: any,
) {
  const value = getValue(v, statePath || "");
  if (typeof value === "object" && Array.isArray(value)) {
    const index = value.findIndex((val) => val.id === elementName);
    return `${index !== -1 ? index : ""}.value`;
  }
  return elementName || "";
}

export function getCurrentStatePath(
  elementName: string | undefined,
  statePath: string | null,
  value?: any,
) {
  if (statePath && statePath !== "" && elementName)
    return `${statePath}.${getNewPathPart(elementName, statePath, value)}`;
  else if (elementName) return getNewPathPart(elementName, statePath, value);
  else if (statePath) return statePath;
  return "";
}

export function getAssignedObs(props: FormElementContainerProps) {
  if (props.valueChangeObs) {
    return props.valueChangeObs;
  } else if (props.stateDispatcherContext.valueChangeObs) {
    return props.stateDispatcherContext.valueChangeObs;
  }
  return NeverObservable;
}

export function getHumanReadableIdentifier(
  elementName: string | undefined,
  statePath: string | null,
) {
  const currentStatePath = getCurrentStatePath(elementName, statePath, {});
  return `statePath ${
    currentStatePath != null && currentStatePath !== ""
      ? currentStatePath
      : '""'
  } and elementName ${elementName || '""'}`;
}

function getNewCompletePathPart(
  elementName: string,
  completeStatePath: string | null,
  v: any,
) {
  const value = getValue(v, completeStatePath || "");
  if (typeof value === "object" && Array.isArray(value)) {
    const index = value.findIndex((val) => val.id === elementName);
    return `${index !== -1 ? index : ""}.value`;
  }
  return elementName || "";
}

export function getCompleteCurrentStatePath(
  props: FormElementContainerProps,
  completeStatePath: string | null,
  value?: any,
) {
  if (
    props.valueChangeObs == null &&
    completeStatePath &&
    completeStatePath !== "" &&
    props.elementName
  ) {
    return `${completeStatePath}.${getNewCompletePathPart(
      props.elementName,
      completeStatePath,
      value,
    )}`;
  } else if (props.elementName) {
    return getNewCompletePathPart(props.elementName, completeStatePath, value);
  } else if (props.valueChangeObs == null && completeStatePath) {
    return completeStatePath;
  }
  return "";
}
