import { DependencyList, RefObject, useLayoutEffect, useRef } from "react";

const focusableElementsSelector = [
  "button",
  "a[href]",
  "input",
  "select",
  "textarea",
  '[tabindex]:not([tabindex="-1"])',
].join(", ");

/**
 * This custom React hook, useFocusElement, is designed to manage and enhance accessibility
 * within components, such as modals, dialogs, or drawers, by automatically focusing the first
 * focusable element when the component is rendered or becomes visible.
 * It allows for various configurations, including delaying the focus action,
 * preserving the scroll position, and optionally setting the tab index of the focusable element.
 * Additionally, it includes the capability to restore focus to the element that was focused before
 * the component mounted, which is a common accessibility feature for modal dialogs.
 */

export function useFocusElement<T extends HTMLElement>({
  dependencies = [],
  shouldFocus = true,
  delay = 0,
  setTabIndex,
  focusFirstInnerElement,
  fallbackRestoreRef,
  restoreFocus,
  restoreScrollPosition = false,
  setTabIndexFallbackRestoreRef,
}: {
  delay?: number;
  dependencies?: DependencyList;
  fallbackRestoreRef?: HTMLElement | null;
  focusFirstInnerElement?: boolean;
  restoreFocus?: boolean;
  restoreScrollPosition?: boolean;
  setTabIndex?: boolean;
  setTabIndexFallbackRestoreRef?: boolean;
  shouldFocus?: boolean;
} = {}): RefObject<T> {
  const focusableElementRef = useRef<T>(null);
  const lastFocusedElementRef = useRef<HTMLElement | null>(null);
  useLayoutEffect(() => {
    if (restoreFocus) {
      const currentActiveElement = document.activeElement as HTMLElement | null;
      lastFocusedElementRef.current = currentActiveElement;
    }
    const timeout = setTimeout(() => {
      if (!focusableElementRef.current || !shouldFocus) {
        return;
      }

      if (setTabIndex && !focusFirstInnerElement) {
        focusableElementRef.current.tabIndex = -1;
      }

      const focusFirstFocusableElement = () => {
        const focusableElements = (
          focusableElementRef.current as T
        ).querySelectorAll(focusableElementsSelector);
        const firstFocusableElement = Array.from(focusableElements).find(
          (element) => !(element as HTMLInputElement).disabled,
        );
        if (firstFocusableElement) {
          (firstFocusableElement as HTMLInputElement).focus();
        }
      };

      if (restoreScrollPosition) {
        // Save current scroll position.
        const x = window.scrollX;
        const y = window.scrollY;

        if (focusFirstInnerElement) {
          focusFirstFocusableElement();
        } else {
          focusableElementRef.current.focus();
        }

        // Restore the scroll position.
        window.scrollTo(x, y);
      } else {
        if (focusFirstInnerElement) {
          focusFirstFocusableElement();
        } else {
          focusableElementRef.current.focus();
        }
      }
    }, delay);

    return () => {
      clearTimeout(timeout);

      // Restore focus to the last focused element before the component was mounted
      if (restoreFocus) {
        setTimeout(() => {
          if (
            lastFocusedElementRef.current &&
            document.contains(lastFocusedElementRef.current)
          ) {
            lastFocusedElementRef.current.focus();
          } else if (
            fallbackRestoreRef &&
            document.contains(fallbackRestoreRef)
          ) {
            // If lastFocusedElementRef is not in the DOM, focus on the first focusable parent
            if (setTabIndexFallbackRestoreRef) {
              fallbackRestoreRef.tabIndex = -1;
            }
            fallbackRestoreRef.focus();
          }
        }, 0);
      }
    };
  }, [...dependencies, shouldFocus, restoreScrollPosition, delay]);

  return focusableElementRef;
}
