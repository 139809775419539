const withBodyMessage = (data: any, message: string) =>
  data?.body?.message?.includes(message);

const withBodyError = (data: any, message: string) =>
  data?.body?.error?.includes(message);

function isExpectedAuthBehaviour(data: {
  error: { message: string | undefined } | undefined;
}) {
  if (data.error?.message) {
    const { body } = JSON.parse(data.error.message);

    if (
      body?.error?.startsWith("No account with email ") ||
      body.reason?.startsWith("No such user -")
    )
      return true;

    if (body?.reason?.startsWith("Bad password")) return true;
  }
  return false;
}

export function getIgnoreTransportErrorReason(
  status: number,
  data: any,
  route: string | undefined,
): string | null {
  if (status === 409) return "Resource conflict";

  if (
    status === 403 &&
    (withBodyError(data, "No filter approved the request") ||
      withBodyError(data, "Forbidden"))
  )
    return "Bad permission";

  if (status === 401 && withBodyMessage(data, "Token is expired"))
    return "Token is expired";

  if (status === 401 && withBodyError(data, "Token is expired"))
    return "Token is expired";

  if (status === 401 && withBodyError(data, "Could not get raw JWT"))
    return "Could not get raw JWT";

  if (status === 401 && route == "verifyToken") return "Token is not valid";

  if (
    (status === 401 || status === 404) &&
    (route == "login" || route == "authenticate") &&
    isExpectedAuthBehaviour(data)
  )
    return "Expected auth behaviour";

  return null;
}
