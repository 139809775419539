import { PaletteOptions } from "@mui/material";
import {
  PROVIDER_PRIMARY_COLOR,
  PROVIDER_PRIMARY_DARK_COLOR,
  PROVIDER_PRIMARY_LIGHT_COLOR,
  WHITE,
} from "../colors";
import { paletteShared } from "./shared";

export const paletteProvider: PaletteOptions = {
  ...paletteShared,
  primary: {
    dark: PROVIDER_PRIMARY_DARK_COLOR,
    main: PROVIDER_PRIMARY_COLOR,
    light: PROVIDER_PRIMARY_LIGHT_COLOR,
    contrastText: WHITE,
  },
};
