import { useEffect, useState } from "react";

export default function useCountdown(interval: number) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (interval > 1000) setCurrent(interval);
  }, [interval]);

  useEffect(() => {
    if (current < 0) return undefined;
    const timeout = setTimeout(() => {
      const next = current - 1000 >= 1000 ? current - 1000 : 0;
      setCurrent(next);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [current]);

  return Math.floor(current / 1000);
}
