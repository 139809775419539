import { PaletteOptions } from "@mui/material";
import { B2C_PRIMARY_COLOR, PRIMARY_LIGHT_COLOR, WHITE } from "../colors";
import { paletteShared } from "./shared";

export const paletteB2C: PaletteOptions = {
  ...paletteShared,
  primary: {
    dark: B2C_PRIMARY_COLOR,
    main: B2C_PRIMARY_COLOR,
    light: PRIMARY_LIGHT_COLOR,
    contrastText: WHITE,
  },
};
