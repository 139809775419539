import { PaletteOptions } from "@mui/material";
import {
  PRIMARY_COLOR,
  PRIMARY_DARK_COLOR,
  PRIMARY_LIGHT_COLOR,
  WHITE,
} from "../colors";
import { paletteShared } from "./shared";

export const paletteSender: PaletteOptions = {
  ...paletteShared,
  primary: {
    dark: PRIMARY_DARK_COLOR,
    main: PRIMARY_COLOR,
    light: PRIMARY_LIGHT_COLOR,
    contrastText: WHITE,
  },
} as const;
