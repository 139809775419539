/**
 * Reference implementation: https://github.com/enisdenjo/graphql-ws#apollo-client
 * Protocol: https://github.com/enisdenjo/graphql-ws/blob/master/PROTOCOL.md
 */
import { ApolloLink, FetchResult, Observable, Operation } from "@apollo/client";
import { print } from "graphql";
import { Client, ClientOptions, createClient } from "graphql-ws";

export class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ""}`,
                ),
              );
            }

            if (Array.isArray(err))
              return sink.error(
                new Error(
                  err.map((errInstance) => errInstance?.message).join(", "),
                ),
              );

            return sink.error(err);
          },
        },
      );
    });
  }
}
