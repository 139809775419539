/**
 * WebSocket configuration options.
 */
export const WebSocketConfig = {
  /**
   * Frequency the frontend will ping the backend to ensure connection is healthy.
   * @param {number} PING_REFRESH_RATE - The ping frequency in milliseconds.
   */
  PING_REFRESH_RATE: 10_000,
  /**
   * Time frontend will wait to receive pong before considering connection dropped.
   * @param {number} PONG_TIME_OUT_DURATION - The timeout duration in milliseconds.
   */
  PONG_TIME_OUT_DURATION: 5_000,
  /**
   * Buffer time to allow for reconnection after drop to consider the connection dead.
   * @param {number} KEEP_ALIVE_BUFFER_TIME - The buffer time in milliseconds.
   */
  KEEP_ALIVE_BUFFER_TIME: 5_000,
  /**
   * @param {number} RETRY_ATTEMPTS - Amount of times before giving up connection.
   */
  RETRY_ATTEMPTS: Infinity,
};
